const CookieConstants = {
  BAR_REDEEM_EVENT_NAME: "setIbCookie",
  GAME_USER_COOKIE_NAME: "gameIbUser",
  GAME_USER_EVENT_NAME: "setIbGameUser",
  GAME_USER_SEARCH_EVENT_NAME: "getIbGameUser",
  GAME_USER_SEND_EVENT_NAME: "sendIbGameUser",

  SCRIPT_BOOTED: "scriptBooted",
  VISITS_PARAM: "ib_visits",
  WINDOW_WIDTH: "main_width",
  URL_PREFIX: "widget_key",

  UTM_CAMPAIGN_ID: "utm_id",
  UTM_CAMPAIGN_SOURCE: "utm_source",
  UTM_CAMPAIGN_MEDIUM: "utm_medium",
  UTM_CAMPAIGN_NAME: "utm_campaign",
  UTM_CAMPAIGN_TERM: "utm_term",
  UTM_CAMPAIGN_CONTENT: "utm_content"
}
export default CookieConstants