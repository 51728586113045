import JackpotFinalReward from "../../Common/Models/JackpotFinalReward";
import CookieConstants from "./CoookieConstants";
import GamePlayerCookieState from "../../Common/Models/GamePlayerCookieState";
import {PostRequest} from "../../Common/ApiCall/Requests";

function setCookie(name,value,days = 30) {
  let expires = "";
  if(!days) days = 30;
  // if(value) value = JSON.stringify(value)
  let date = new Date();
  date.setTime(date.getTime() + (days*24*60*60*1000));
  expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

async function addVariantToCart(var_id, quantity) {
  const r = await PostRequest(window.location.origin + '/cart/add.js', {
    quantity: quantity,
    id: var_id
  }, {
    Accept: "application/json"
  })
  // const d = () => {
  //   $.ajax({
  //     type: 'POST',
  //     url: '/cart/add.js',
  //     data: {
  //       quantity: quantity,
  //       id: var_id
  //     },
  //     dataType: 'json',
  //     success: function (data) {
  //       // alert('Item added to cart');
  //     }
  //   })
  // }
  // d()
  // fetch(window.location.origin +  "/cart/" + var_id + ":" + quantity)
}

function windowScrollToTop() {
  window.scroll(0, 0)
  // window.postMessage("scrollToTop", "*")
}

function setGameUser(userDetails: GamePlayerCookieState) {
  if(userDetails) {
    windowParentSetCookie(CookieConstants.GAME_USER_EVENT_NAME, userDetails)
  }
}

function windowParentSetCode(code_name:string,code_value: any, expiry_seconds: any, reward: string) {
  let exp = addSeconds(new Date(), expiry_seconds)
  let b = {
    name: code_name,
    code: code_value,
    expiry: exp,
    text: reward
  }
  // console.log("mess", b)
  window.postMessage(b, "*")
}

function windowParentSetCookie(eventName: string, eventDetails = {}) {
  let body = {
    name: eventName,
    data: JSON.stringify(eventDetails)
  }
  window.postMessage(body, "*")
}

function checkUserMessage() {
  window.postMessage(CookieConstants.GAME_USER_SEARCH_EVENT_NAME, "*")
}

function addSeconds(date: Date, seconds: number) {
  return new Date(date.getTime() + seconds * 1000);
}
function addMinutes(date: { getTime: () => number; }, minutes: number) {
  return new Date(date.getTime() + minutes*60000);
}

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function hexfix(str: string) {
  str = str.substring(1)
  if (str.length === 3) {
    str = str.split('').map(function (hex) {
      return hex + hex;
    }).join('');
  }
  return str
}

function getRandomNumber0toN(N: number) {
  return Math.floor(Math.random() * (N+1));
}

function getRandomAlphabet() {
  let allAlphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return allAlphabets[getRandomNumber0toN(25)]
}

function get2DArray(rows: number, cols: number, element = null) {
  return new Array(rows).fill(element).map(() => new Array(cols).fill(element));
}

const capitalize = (s: string) => s && s[0] && s[0].toUpperCase() + s.slice(1)

function shuffle(array: any[]) {
  let currentIndex = array.length,  randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
}

const finalAddBar = async (reward: JackpotFinalReward) => {
  const text = reward.code
  window.navigator.clipboard.writeText(text)
  await redeemBarReward(reward)
  // window.location.href = getRedeemLink(reward.code)
}

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const randomNames =  [
  "Ivy", "Richa", "Prita", "Karabi", "Isha", "Punam", "Rohini", "Lavali", "Sanjukta", "Sampriti", "Veena", "Sucheta", "Nalini", "Gautami", "Shridula", "Chandrika", "Premala", "Tarala", "Tanseem", "Tanima", "Sunayana", "Urmila", "Meghamala", "Gitanjali", "Neelkamal", "Samit", "Laabha", "Dristi", "Tanushri", "Tarika", "Lily", "Raka", "Khyati", "Sachi", "Vanita", "Leela", "Tarana", "Sudeepa", "Saeeda", "Hima", "Varana", "Manorama", "Tamanna", "Ekata", "Zarine", "Sona", "Triguna", "Soorat", "Atreyi", "Safia"
]

function getQueryVariable(variable: string) {
  var query = window.location.search.substring(1);
  // console.log(query)//"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
  // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    if(pair[0] == variable){return pair[1];}
  }
  return false;
}

function removeSpaces(s: string | null) {
  if(!s) return ""
  return s.replace(/\s+/g, '');
}

function yesNo(a: boolean) {
  return a ? "yes" : "no"
}

function isMobile() {
  return window.innerWidth < 768
}

function getScrollPercent() {
  let h = document.documentElement, b = document.body, st = 'scrollTop', sh = 'scrollHeight';
  return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
}

// function lazyWithPreload(factory) {
//   const Component = React.lazy(factory);
//   Component.preload = factory;
//   return Component;
// }

export {
  windowScrollToTop, setGameUser,
  checkUserMessage, validateEmail, hexfix,
  capitalize, getRandomAlphabet, getRandomNumber0toN,
  get2DArray, shuffle, finalAddBar, getRandomInt,
  randomNames, getQueryVariable, removeSpaces,
  yesNo, isMobile, setCookie, getCookie,
  eraseCookie, addVariantToCart, getScrollPercent
}