import * as React from "react";
import App from "./components/App";
import { createRoot } from "react-dom/client";
// import {init} from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import "./input.css";
import {Provider} from "react-redux";
import {store} from "./app/store";
import {BrowserRouter} from "react-router-dom";

// init({
//   dsn: "https://aa1aa49ea34940c186ba416a375939ba@o1118235.ingest.sentry.io/6588267",
//   integrations: [new BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const container = document.getElementById("engagebud-widget");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
