export const ApiConstants = {
  // PROD_SERVER_URL: 'https://dev.influencerbit.com',
  PROD_SERVER_URL: 'https://robin.engagebud.io',
  DEV_SERVER_URL: "http://localhost:3000" ?? 'https://api.engagebud.io' ?? "http://localhost:3000",
  SERVER_NAMESPACE: '/ib/api/widget/v1',
  V2_SERVER_NAMESPACE: '/ib/api/widget/v2',
  RUN_PROD_SERVER: true,
  LOCALHOST_SERVER: process.env.NODE_ENV === 'development',
  PROD_SERVER: process.env.NODE_ENV === 'production',
  DEV_DOMAIN: 'localhost',
  PROD_DOMAIN: window.location.host,
  AUTH_COOKIE_NAME: process.env.NODE_ENV === 'development' ? 'ibDevCommunityAppAuth' : 'ibCommunityAppAuth',
  AUTH_COOKIE_PATH: "/",
  COOKIE_IB_VISITS: "ibCommunityUserVisit"
}

export function V1ServerURL(url = '') {
  return (ApiConstants.PROD_SERVER ? ApiConstants.PROD_SERVER_URL : ApiConstants.DEV_SERVER_URL) + ApiConstants.SERVER_NAMESPACE + url
}

export function V2ServerURL(url = '') {
  return (ApiConstants.LOCALHOST_SERVER ? ApiConstants.DEV_SERVER_URL : ApiConstants.PROD_SERVER_URL) + ApiConstants.V2_SERVER_NAMESPACE + url
}