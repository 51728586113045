import {PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import {ApiConstants, V1ServerURL} from "../ApiConstants";

const ShowNudgeApi = async (v: any = {}) => {
  let apiResponse;
  if (ApiConstants.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(V1ServerURL("/nudge/show"), v)
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {return {"data":{},"success":true,"error":null}}

export default ShowNudgeApi
