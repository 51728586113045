const CookieNames = {
  CouponCodeValue: "ibBarCouponCode",
  CouponCodeSecondsLeft: "ibBarCouponLeft",
  updateBottomBarIntervalName: "bottomIntervalCookie",
  ibUserCookieName: "gameIbUser",
  ibCommunityFrameId: "ib-community-frame",
  ibRedeemBar: "influencerbit-redeem-bar",
  bgColor: "#000",
  textColor: "#FFF",
  codeBgColor: "#FFF",
  codeTextColor: "#000",
  engagebudShopDomain: "ebDomainName",
  CouponCodeText: "ibBarCouponText",

  CAMPAIGN_OPENED_PREFIX: "opened_",
  CAMPAIGN_DONE_PREFIX: "camp_",

  SHOPIFY_COOKIES: {
    DISCOUNT_CODE: "discount_code"
  }
}

export default CookieNames