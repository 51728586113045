import {LauncherStateTypes} from "../../components/App";

const MainConstants = {
  POLL_DIV_ID: "eb-poll-panel",

  EB_GAMES_URL: "https://games.engagebud.io",
  DEV_URL: "http://localhost:5001",

  LAUNCHER_STATES: {
    HIDDEN: "hidden",
    LAUNCHED: "launched",
    SMALL: "small",
    FULL: "full"
  },

  KEYS: {
    WIDGET_PREVIEW: "ebwtpv",
    WIDGET_PV_CAMPAIGN_ID: "ebwtcid",
    PREVIEW: "ebnudpv",
    PREVIEW_CAMPAIGN_ID: "ebpvcid"
  },

  HTML_ELEMENTS: {
    DIV: "div",
    IFRAME: "iframe",
    IMAGE: "img",
    SPAN: "span",
  }
}

export default MainConstants