import {useRef, useState} from "react";

function useStateAndRef(initial: any) {
  const [value, setValue] = useState(initial);
  const valueRef = useRef(value);
  valueRef.current = value;
  return [value, setValue, valueRef];
}

export {
  useStateAndRef
}