const WindowListenerNames = {
  SCROLL_TO_TOP_LISTENER_NAME: "scrollToTop",
  setIbGameUserEventName: "setIbGameUser",
  sendIbGameUserEventName: "sendIbGameUser",
  getIbGameUserEventName: "getIbGameUser",
  closeGameModal: "closeGameModal",
  setCookieEventName: "setIbCookie",
  getCookieEventName: "getIbCookie",
  cookieChanged: "cookieChanged",

  ACTION_EVENTS: {
    APPLY_COUPON_EVENT_NAME: "APPLY_COUPON",
    NAVIGATE_EVENT_NAME: "NAVIGATE",
    ADD_TO_CART: "addToCart",
    CLOSE_MODAL: "closeModal",
    RESET_IFRAME_HEIGHT: "resetHeight"
  },

  APP_BREW_EVENTS: {
    SHOW_TOAST_EVENT_NAME: "SHOW_TOAST",
    APPLY_COUPON_EVENT_NAME: "APPLY_COUPON",
    NAVIGATE_EVENT_NAME: "NAVIGATE"
  }
}

export default WindowListenerNames