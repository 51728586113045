import {useEffect, useState} from "react";
import * as React from "react";
import {LauncherStateTypes} from "../App";
import {
  addVariantToCart,
  eraseCookie,
  getCookie, getScrollPercent,
  isMobile,
  setCookie,
  windowScrollToTop,
  yesNo
} from "../helperFunctions/helperFunctions";
import CookieNames from "../../Common/Constants/CookieNames";
import WindowListenerNames from "../../Common/Constants/WindowListenerNames";
import GetWidgetDetailsApi from "../../Common/ApiCall/Widget/GetWidgetDetailsApi";
import CookieConstants from "../helperFunctions/CoookieConstants";
import ShowNudgeApi from "../../Common/ApiCall/Widget/ShowNudgeApi";
import ClickNudgePlayApi from "../../Common/ApiCall/Widget/ClickNudgePlayApi";
import CloseNudgeApi from "../../Common/ApiCall/Widget/CloseNudgeApi";
import {useStateAndRef} from "../helperFunctions/ReactHelperFunction";
import ClickBannerApi from "../../Common/ApiCall/Widget/ClickBannerApi";
import backIcon from '../../Common/ApiCall/Widget/go-back-arrow.svg'
import NewReferredUserApi from "../../Common/ApiCall/Widget/NewReferredUserApi";
import LauncherIcons from "../../Common/Constants/LauncherIcons";
import MainConstants from "../../Common/Constants/MainConstants";
import GetPollDetailsApi from "../../Common/ApiCall/Widget/GetPollDetailsApi";
import CheckGamePlayedApi from "../../Common/ApiCall/Widget/CheckGamePlayedApi";
import ClickStickyNudgePlayApi from "../../Common/ApiCall/Widget/ClickStickyNudgePlayApi";
import ShowStickyNudgeApi from "../../Common/ApiCall/Widget/ShowStickyNudgeApi";

const publicIp = require("react-public-ip");

export const GameFrame = "engagebudGameFrame"

export const clr = "#FF4697"

declare global {
  interface Window {
    FB: any,
    Intercom: any,
    ib: any
  }
}

const DEFAULT_COUPON_CODE = "Your Coupon Code"
const MOBILE_DEFAULT_COUPON_CODE = "Code: "

const LauncherComponents = () => {
  const [initial, setInitial] = useState(false)
  const [details, setDetails, detailsRef] = useStateAndRef(null)
  const [shopDomain, setShopDomain] = useState<string | null>(null)
  const [modalTargetLinks, setModalTargetLinks] = useState<any>({})
  const [widget, setWidget, widgetRef] = useStateAndRef(null)
  const [apiCalled, setApiCalled] = useState(false)
  const [modalOpen, setModalOpen] = useState<any>(null)
  const [spacing, setSpacing] = useState({
    vertical: 20, side: 20
  })
  const [launcherState, setLauncherState, launcherStateRef] = useStateAndRef(MainConstants.LAUNCHER_STATES.HIDDEN);
  const sp = new URLSearchParams(window.location.search)

  const init = () => {
    try {
      windowEventListener()
      // addSearchIframe()
      shopInit()
      checkReferredCampaign()
    } catch (e) {
      console.error("INIT FAILED", e)
    }
  }

  const bannerClick = (cid: any) => {
    let values = {
      campaign_id: cid ?? details.campaign.id,
      mobile: yesNo(window.innerWidth < 768),
      utms: window.location.search
    }
    ClickBannerApi(values)
    openGamesModal(cid)
  }

  const openGamesModal = (key = null) => {
    if(!key) key = widgetRef.current?.campaign_id
    setModalOpen(key)
    setLauncherState(MainConstants.LAUNCHER_STATES.FULL)
    setCookie(CookieNames.CAMPAIGN_OPENED_PREFIX + key, "1")
  }

  const closeGamesModal = (cid = null) => {
    setModalOpen(null)
    minimize(cid, false)
  }

  const minimize = (campaignId, start = true) => {
    let newState: LauncherStateTypes = MainConstants.LAUNCHER_STATES.HIDDEN
    let mobile = isMobile()
    let currWidgetCampaign = widgetRef.current
    // let currWidgetCampaign = currentWidget?.widgets[campaignId]?.widget_details ?? widgetRef.current
    if(mobile && currWidgetCampaign?.mobile_pulltab) newState = MainConstants.LAUNCHER_STATES.SMALL
    if(!mobile && currWidgetCampaign?.desktop_pulltab) newState = MainConstants.LAUNCHER_STATES.SMALL
    setLauncherState(newState)
  }

  const _openModalStickyNudge = function (e = null) {
    e && e.preventDefault();
    let played = !!getCookie(CookieNames.CAMPAIGN_OPENED_PREFIX + widgetRef?.current?.campaign_id)
    openStickyNudgeGame(played)
    openGamesModal()
  };

  const refreshModal = function (elementId = null) {
    if(elementId) {
      let i = document.getElementById(elementId)
      if(i) {
        i.style.minHeight = window.innerHeight + "px"
        i.contentWindow.postMessage({
          action: WindowListenerNames.ACTION_EVENTS.CLOSE_MODAL
        }, "*")
        setTimeout(() => {
          i.src= i.src
        }, 300)
      }
    }
  }

  const closeModal = function (elementId = null, refresh = false) {
    let cid = null
    if(elementId) {
      let ns = elementId.match(/\d+$/)
      cid = ns[ns.length - 1]
    }
    if(elementId) {
      let i = document.getElementById(elementId)
      if(i) {
        i?.contentWindow.postMessage({
          action: WindowListenerNames.ACTION_EVENTS.CLOSE_MODAL
        }, "*")
        if(refresh) {
          setTimeout(() => {
            window.location.href += "?reload=1"
          }, 350)
        }
      }
    }
    closeGamesModal(cid)
  };

  function receiveMessage(event) {
    let mainEventName = event.data.name
    let eventAction = event.data.action
    let eventPayload = event.data.payload
    let eventData = event.data
    // Do we trust the sender of this message?
    // if (evt.origin !== "https://localhost:44335") {
    //   return;
    // }
    if (event.data.type === "frame-resized") {
      // console.log("Got message: " + JSON.stringify(event.data) + " from origin: " + event.origin, event);
      let frameTarget = event.data.target;
      let frameHeight = parseInt(event.data.value) || 0
      if(frameHeight === 0) return
      let windowHeight = window.innerHeight
      let iframeIds = [CookieNames.ibCommunityFrameId, GameFrame]
      // console.log(frameTarget,frameHeight, iframeIds.filter(a => (frameTarget).includes(a)))
      iframeIds.filter(a => frameTarget.includes(a)).map((i) => {
        let minHeight = 550;
        let maxHeight = 2000;
        if(window.innerHeight > 768) maxHeight = 2300
        if(i.includes(GameFrame)) {minHeight = windowHeight}
        let height = Math.max(frameHeight, minHeight)
        height = Math.max(500, height)
        height = Math.min(maxHeight, height)
        // console.log(i, frameHeight, frameTarget)
        // console.log(frameHeight, windowHeight, height)
        let iframe = document.getElementById(frameTarget)
        if(iframe) {
          let iframeCurrentHeight = iframe.offsetHeight
          height = Math.max(iframeCurrentHeight, height)
            // console.log(i, "iframe", height)
          // console.log(frameHeight, windowHeight, height)
          iframe.style.minHeight = height + "px"
          let b = {"type": "resize-game", "value": height, "target": i}
          iframe.contentWindow.postMessage(b, "*")
        }
      })
    }
    if(eventAction === WindowListenerNames.ACTION_EVENTS.RESET_IFRAME_HEIGHT) {
      let target = eventPayload.target;
      let iframe = document.getElementById(target)
      if(iframe) {
        iframe.style.minHeight = window.innerHeight + "px"
      }
    }
    if(eventAction === WindowListenerNames.ACTION_EVENTS.ADD_TO_CART) {
      let variant_id = eventPayload.variant_id
      let quantity = eventPayload.quantity || 1
      addVariantToCart(variant_id, quantity)
    }
    if(eventData === WindowListenerNames.SCROLL_TO_TOP_LISTENER_NAME) {
      windowScrollToTop()
    }
    if(eventData === WindowListenerNames.closeGameModal) {
      closeModal()
    }
    if(mainEventName === WindowListenerNames.setCookieEventName) {
      let code = event.data.code
      let now = event.data.expiry
      let text = event.data.text
      setCookie(CookieNames.SHOPIFY_COOKIES.DISCOUNT_CODE, code)
      setCookie(CookieNames.CouponCodeValue, code)
      setCookie(CookieNames.CouponCodeSecondsLeft, now)
      setCookie(CookieNames.CouponCodeText, text)
      checkIfCouponCodeCookie()
      setTimeout(() => {
        checkIfCouponCodeCookie()
      }, 500)
    }
    if(mainEventName === WindowListenerNames.setIbGameUserEventName) {
      let d = event.data.data;
      setCookie(CookieNames.ibUserCookieName, d)
    }
    if(mainEventName === WindowListenerNames.getIbGameUserEventName) {
      checkIfGameUserPresent()
    }
    if(mainEventName === WindowListenerNames.cookieChanged) {
      checkIfCouponCodeCookie()
    }
  }
  function windowEventListener() {
    window.addEventListener("message", receiveMessage, false)
  }

  function checkIfCouponCodeCookie() {
    let code = getCookie(CookieNames.CouponCodeValue)
    let rewardString = getCookie(CookieNames.CouponCodeText)
    let timeStart = new Date(document.cookie.replace(/(?:(?:^|.*; *)ibBarCouponLeft *= *([^;]*).*$)|^.*$/, "$1"))
    if(!code) return;
    if(!code || isNaN(timeStart)) {
      // console.error("Invalid Date")
      return
    }
    let nowTime = new Date()
    let sec = (timeStart - nowTime) / 1000;
    // console.log(timeStart, nowTime, sec)
    addBottomBar(code, sec, rewardString)
  }

  function checkIfGameUserPresent() {
    let details = getCookie(CookieNames.ibUserCookieName)
    if(details) {
      window.postMessage({
        name: WindowListenerNames.sendIbGameUserEventName,
        data: details
      }, "*")
    }
  }

  function toHHMMSS(time_in_seconds) {
    let finalStr = ""
    let sec_num = parseInt(time_in_seconds, 10)
    let hours: string | number = Math.floor(sec_num / 3600);
    let minutes: string | number = Math.floor(sec_num / 60) % 60;
    let seconds: string | number = sec_num % 60;
    if(hours < 10) hours = "0" + hours
    if(minutes < 10) minutes = "0" + minutes
    if(seconds < 10) seconds = "0" + seconds
    finalStr = ( hours!=="00" ? hours + "h " : "") + minutes + "m " + seconds + "s "
    return finalStr
  }

  function addBottomBar(code, seconds_left, rewardString = DEFAULT_COUPON_CODE, auto = "yes") {
    let details = detailsRef.current
    if(details===null || details.bar===null || details.bar?.active===false) return;
    if(rewardString === null) rewardString = DEFAULT_COUPON_CODE
    let bb = document.createElement(MainConstants.HTML_ELEMENTS.DIV)
    let barId = CookieNames.ibRedeemBar
    let barCloseButtonId = barId + "close-btn"
    let barTextColor = details.bar?.bar_text_color || CookieNames.textColor
    bb.id = barId
    bb.key = barId
    if(details.bar?.bar_position === 'top') {
      bb.style.top = "0px"
    } else {
      bb.style.bottom = "0px"
    }
    bb.style.position = "fixed"
    bb.style.zIndex = details.bar?.z_index || 10
    bb.style.left = 0
    bb.style.right = 0
    bb.style.display = "flex"
    bb.style.alignItems = "baseline"
    bb.style.flexWrap = "wrap"
    bb.style.justifyContent = "center"
    // bb.style.minHeight = "50px"
    bb.style.maxHeight = "90px"
    bb.style.fontSize = "14px"
    bb.style.fontWeight = 600
    bb.style.backgroundColor = details.bar?.bar_bg_color || CookieNames.bgColor
    bb.style.border = "none"
    bb.style.padding = "10px 60px"
    if(isMobile()) bb.style.padding = "8px 20px"
    let bb_text = document.createElement("span")
    let bb_coupon = document.createElement("span")
    let bb_timer = document.createElement("span")
    let bb_close_btn = document.createElement("button")
    bb_text.id = barId + "-text"
    bb_text.style.color = barTextColor
    bb_text.innerText = rewardString
    if(isMobile()) bb_text.innerText = MOBILE_DEFAULT_COUPON_CODE
    bb.appendChild(bb_text)
    let codeBgColor = details.bar?.code_bg_color || CookieNames.codeBgColor
    let codeTextColor = details.bar?.code_text_color || CookieNames.codeTextColor
    bb_coupon.id = barId + "-coupon"
    bb_coupon.style.color = codeTextColor
    bb_coupon.style.backgroundColor = codeBgColor
    bb_coupon.style.padding = "2px 5px"
    bb_coupon.style.margin = "0 1em"
    bb_coupon.style.borderRadius = "3px"
    bb_coupon.style.border = `1px dashed ${codeBgColor}`
    bb_coupon.innerText = code.toUpperCase()
    bb_coupon.onclick = () => {window.navigator.clipboard.writeText(code)}
    bb.appendChild(bb_coupon)
    bb_timer.id = barId + "-time"
    bb_timer.style.color = barTextColor
    bb_timer.innerText = `${!isMobile()?"Reserved for " : ""}${toHHMMSS(seconds_left)}`
    details.bar?.reward_timer_active && bb.appendChild(bb_timer)
    bb_close_btn.id = barCloseButtonId
    bb_close_btn.style.position = "absolute"
    bb_close_btn.style.top = "5px"
    bb_close_btn.style.right = "0px"
    bb_close_btn.style.width = "36px"
    bb_close_btn.style.height = "36px"
    bb_close_btn.style.right = "30px"
    bb_close_btn.style.padding = "10px"
    bb_close_btn.style.borderRadius = "0"
    bb_close_btn.style.lineHeight = "1"
    bb_close_btn.style.transform = "none"
    bb_close_btn.style.opacity = .5
    bb_close_btn.style.display = "inline-flex"
    bb_close_btn.style.alignItems = "center"
    bb_close_btn.style.justifyContent = "center"
    bb_close_btn.style.border = "none"
    bb_close_btn.style.fontSize = "16px"
    bb_close_btn.style.textDecoration = "none"
    bb_close_btn.style.backgroundColor = "transparent"
    bb_close_btn.style.fill = barTextColor
    bb_close_btn.style.cursor = "pointer"
    bb_close_btn.innerHTML = '<svg width="16" fill={barTextColor} height="16"><path d="M15.35 14.65a.48.48 0 0 1 0 .7.48.48 0 0 1-.7 0L8 8.71l-6.65 6.64a.47.47 0 0 1-.35.15.47.47 0 0 1-.35-.15.48.48 0 0 1 0-.7L7.29 8 .65 1.35a.48.48 0 0 1 0-.7.48.48 0 0 1 .7 0L8 7.29 14.65.65a.48.48 0 0 1 .7 0 .48.48 0 0 1 0 .7L8.71 8z"/></svg>'
    bb.appendChild(bb_close_btn)
    bb_close_btn.addEventListener("click", function() {
      removeBottomBar()
    })
    if(document.getElementById(barId)) return
    document.body.appendChild(bb)
    if(auto) {
      const intervalId = setInterval(function() {
        updateBottomBar()
      }, 1000)
      setCookie(CookieNames.updateBottomBarIntervalName, intervalId)
    }
  }

  function getSearchParamsString(url = "") {
    let l = decodeURI(window.location.search)
    let sub = ""
    if(l.length > 0) sub = l.substring(1)
    l = "?head=false&wmode=transparent&widget_head=1&" + sub + "&"
    return l
  }

  const getGameUrl = () => {
    return process.env.NODE_ENV==="development" ? MainConstants.DEV_URL : MainConstants.EB_GAMES_URL
  }

  // what should we do when scrolling occurs
  function runOnScroll(element, campaigns) {
    let scrollPercent = getScrollPercent()
    if(launcherStateRef.current !== MainConstants.LAUNCHER_STATES.FULL) {
      for(let c of campaigns) {
        if(scrollPercent > c.value && !getCookie(CookieNames.CAMPAIGN_OPENED_PREFIX + c.id)) {
          openGamesModal(c.id)
          break;
        }
      }
    }
  };

  function addIframes() {
    if(Object.keys(modalTargetLinks).length > 1) return
    let widgets = details.widgets
    let keys = Object.keys(widgets)
    let links = {}
    let scrollCampaigns = []
    // console.log(widgets, keys)
    let pageVisits = Number(getCookie(CookieConstants.VISITS_PARAM) || 0)
    let inc = false
    for(let i of keys) {
      let curr = widgets[i]
      // console.log(curr, keys, i)
      let showFilters = curr.widget_details?.filters || []
      let show = showFilters.length === 0;
      let rejectFilters = curr.widget_details?.reject_filters || []
      showFilters.map((k) => {
        if(window.location.href.toLowerCase().includes(k.toLowerCase())) {
          show = true;
        }
      })
      rejectFilters.map((k) => {
        if(window.location.href.toLowerCase().includes(k.toLowerCase())) {
          show = false;
        }
      })
      if(show) {
        let mainUrl = getGameUrl()
        let link;
        // link = details.campaign.url
        if(!link) link = mainUrl + "/" + curr.page_id
        if(curr.gamename != "game_zone") {
          link = mainUrl + "/single/" + curr.page_id
        }
        if(curr.gamename == "survey") {
          link = mainUrl + "/survey/" + curr.page_id
        }
        if(link) {
          link += getSearchParamsString(window.location.search)
          let ul = new URL(link)
          ul.searchParams.set(CookieConstants.VISITS_PARAM, String(pageVisits))
          if(!inc) {
            setCookie(CookieConstants.VISITS_PARAM, pageVisits + 1)
            inc = true
          }
          let player = getCookie(CookieConstants.GAME_USER_COOKIE_NAME)
          if(player) {
            let playerObj = JSON.parse(player)
            let playerId = playerObj.player_id
            let playerEmail = playerObj.email
            let playerLastVisit = playerObj.last_visit
            let playerUserId = playerObj.common_user_id
            ul.searchParams.set("ib_gamer_id", playerId)
            ul.searchParams.set("ib_gamer_email", playerEmail)
            ul.searchParams.set("ib_gamer_last", playerLastVisit)
            if(playerUserId) {
              ul.searchParams.set("ib_user_id", playerUserId)
            }
            ul.searchParams.set("skip", "1")
          }
          ul.searchParams.set(CookieConstants.WINDOW_WIDTH, String(window.innerWidth))
          ul.searchParams.set(CookieConstants.URL_PREFIX, `${GameFrame}_${i}`)
          links = {...links, [i]: ul.toString()}
          let mobile = isMobile()
          if(mobile && curr?.widget_details?.mobile_scroll) {
            scrollCampaigns.push({
              id: curr?.id,
              value: curr?.widget_details?.mobile_scroll_value
            })
          }
          if(!mobile && curr?.widget_details?.desktop_scroll) {
            scrollCampaigns.push({
              id: curr?.id,
              value: curr?.widget_details?.desktop_scroll_value
            })
          }
        }
      }
      eventClicks(i, curr.widget_details)
    }
    document.defaultView.onscroll = evt => runOnScroll(evt, scrollCampaigns)
    setModalTargetLinks(links)
  }

  function updateBottomBar() {
    try {
      let el2 = document.getElementById(CookieNames.ibRedeemBar)
      let rewardString = getCookie(CookieNames.CouponCodeText)
      let code = getCookie(CookieNames.CouponCodeValue)
      let myIntervalId = getCookie(CookieNames.updateBottomBarIntervalName)
      let timeStart = new Date(document.cookie.replace(/(?:(?:^|.*; *)ibBarCouponLeft *= *([^;]*).*$)|^.*$/, "$1"))
      if(isNaN(timeStart)) {
        if(el2) {
          document.body.removeChild(el2)
        }
        clearInterval(myIntervalId)
        return
      }
      let nowTime = new Date()
      let sec = (timeStart - nowTime) / 1000;
      if(sec < 0) {
        removeBottomBar()
      } else {
        let barId = CookieNames.ibRedeemBar
        el2 = document.getElementById(barId)
        let el3 = document.getElementById(barId + "-time")
        let el4 = document.getElementById(barId + "-coupon")
        if(el2 && el3) {
          if(el4.innerText !== code) {
            el4.innerText = code
          }
          el3.innerText = `${!isMobile()?"Reserved for " : ""}${toHHMMSS(sec)}`
          // updateBottomBar(sec - 1)
        } else {
          addBottomBar(code, sec, rewardString , false)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  function removeBottomBar() {
    emptyCodeCookies()
    let barId = CookieNames.ibRedeemBar
    let el2 = document.getElementById(barId)
    document.body.removeChild(el2)
    let b = document.getElementById(barId)
    if(b) {
      b.slideDown(1000)
    }
  }

  function emptyCodeCookies() {
    eraseCookie(CookieNames.CouponCodeValue)
    eraseCookie(CookieNames.CouponCodeSecondsLeft)
  }

  const getWidgetDetails = async () => {
    try {
      let values = {
        shop: shopDomain,
        query: window.location.search,
        link: window.location.href
      }
      const r = await GetWidgetDetailsApi(values)
      if(r.success) {
        setDetails(r.data)
        setWidget(r.data.widget_details)
        setApiCalled(true)
      } else {
        // BadNotif(r)
        console.error("API CALL FAILED. Res: ", r)
      }
    } catch (e) {
      console.error("API CALL FAILED. Error: ", e.message)
    }
  }

  useEffect(() => {
    // console.log(launcherState, modalOpen)
  }, [launcherState, modalOpen])

  const startLauncher = () => {
    let mainWidget = widget
    let sta: LauncherStateTypes = MainConstants.LAUNCHER_STATES.HIDDEN
    if(sp.get(MainConstants.KEYS.PREVIEW) == "1") {
      setTimeout(() => {
        let a = String(sp.get(MainConstants.KEYS.PREVIEW_CAMPAIGN_ID))
        let cids = Object.keys(detailsRef.current.widgets)
        openGamesModal(cids.includes(a) ? a : null)
      }, 500)
    } else {
      let showFilters = mainWidget?.filters || []
      let show = showFilters.length === 0
      let rejectFilters = mainWidget?.reject_filters || []
      showFilters.map((k) => {
        if(window.location.href.toLowerCase().includes(k.toLowerCase())) show = true;
      })
      rejectFilters.map((k) => {
        if(window.location.href.toLowerCase().includes(k.toLowerCase())) show = false;
      })
      if(show) {
        if(sp.get(MainConstants.KEYS.WIDGET_PREVIEW) == "1") {
          let a = String(sp.get(MainConstants.KEYS.WIDGET_PV_CAMPAIGN_ID))
          let tempWidget = detailsRef.current?.widgets[a]?.widget_details
          if(tempWidget) {
            mainWidget = tempWidget
            setWidget(tempWidget)
          }
        }
        let v = Number(getCookie(CookieConstants.VISITS_PARAM) || 0)
        if(v < 2) {
          let mobile = isMobile()
          if((mobile && mainWidget.show_mobile) || (!mobile && mainWidget.show_desktop)) {
            sta = MainConstants.LAUNCHER_STATES.LAUNCHED
          }
        }
        if(sta !== MainConstants.LAUNCHER_STATES.LAUNCHED) {
          minimize(mainWidget?.campaign_id)
        } else {
          let delay = 0
          let mobile = isMobile()
          if(mobile && mainWidget.mobile_delay) delay = mainWidget.mobile_delay_seconds
          if(!mobile && mainWidget.desktop_delay) delay = mainWidget.desktop_delay_seconds
          setTimeout(() => {
            setLauncherState(sta)
            if(mainWidget.timeout) {
              setTimeout(() => {
                if(launcherStateRef.current === MainConstants.LAUNCHER_STATES.LAUNCHED) {
                  closeModalLauncher(true)
                }
              }, (mainWidget.timeout_after_seconds || 10) * 1000)
            }
          }, delay*1000)
        }
      }
    }
  }

  const closeModalLauncher = function (auto = false) {
    if(!auto) {
      let values = {
        campaign_id: details.campaign.id,
        mobile: yesNo(window.innerWidth < 768),
        utms: window.location.search
      }
      CloseNudgeApi(values)
    }
    closeGamesModal()
  };

  const openModalLauncher = function () {
    let values = {
      campaign_id: details.campaign.id,
      mobile: yesNo(window.innerWidth < 768),
      utms: window.location.search
    }
    ClickNudgePlayApi(values)
    openGamesModal()
  };

  const openStickyNudgeGame = (played: boolean) => {
    if(played) return
    let values = {
      campaign_id: details.campaign.id,
      mobile: yesNo(window.innerWidth < 768),
      utms: window.location.search
    }
    ClickStickyNudgePlayApi(values)
  }

  async function shopInit(index = 0) {
    try {
      // console.log("INDEX", index)
      if(index > 2) return;
      // console.log("SHOP", document.currentScript, document.currentScript?.src)
      let shopDomainName = getCookie(CookieNames.engagebudShopDomain)
      // console.log("domain", shopDomainName, index, new Date().getTime())
      if(shopDomainName !== null && shopDomainName !== "") {
        setShopDomain(shopDomainName)
      } else {
        setTimeout(() => {
          shopInit(index + 1)
        }, 250)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const checkReferredCampaign = async () => {
    let code = sp.get("rc")
    let cid = sp.get("cid")
    let campaignCookieName = "eb_" + cid + "_" + "r_cid"
    let c = getCookie(campaignCookieName)
    if(c) {
    //  Old User
    } else {
      if(cid && code) {
        const ipv4 = await publicIp.v4() || "";
        NewReferredUserApi({
          cid: cid,
          rc: code,
          ip: ipv4
        })
        setCookie(campaignCookieName, JSON.stringify({
          value: 1,
        }), 28)
      }
    //  New User
    }
  }

  const getPollUrl = (poll_id: number) => {
    return [getGameUrl() , "poll", poll_id].join("/")
  }

  const checkThanksPageCampaign = async () => {
    if(window.location.href.includes("/orders/")) {
      let el = document.getElementById(CookieNames.ibCommunityFrameId)
      if(el) {
        let mainCampaign = null
        let totalPrice  = Number(el.getAttribute("data-total-price"))
        let orderId = el.getAttribute("data-order-id")
        let currency = el.getAttribute("data-currency")
        let customerId = el.getAttribute("data-customer-id")
        let customerEmail = el.getAttribute("data-customer-email")
        console.log("FOUND IB COmponent", totalPrice, orderId, currency, customerEmail, customerId)
        if(totalPrice && orderId) {
          totalPrice /= 100.0;
          let thanksCampaigns = detailsRef.current?.thanks;
          let keys = thanksCampaigns ? Object.keys(thanksCampaigns) : []
          for(let i of keys) {
            let c = thanksCampaigns[i]
            if(c) {
              if(c?.thanks?.min_value <= totalPrice && c?.thanks?.max_value >= totalPrice) {
                mainCampaign = c
                break;
              }
            }
          }
        }
        console.log("CAMPAIGN TO SHOW", mainCampaign)
        if(mainCampaign) {
          let link = new URL(mainCampaign.url)
          if(process.env.NODE_ENV === "development") link = new URL(mainCampaign.url.replace(MainConstants.EB_GAMES_URL, MainConstants.DEV_URL))
          link.searchParams.set("order_id", orderId)
          link.searchParams.set("total_price", String(totalPrice))
          link.searchParams.set("currency", currency)
          link.searchParams.set("customer_id", customerId)
          link.searchParams.set("email", customerEmail)
          link.searchParams.set("force_skip", "1")
          let v = {
            order_id: orderId,
            campaign_id: mainCampaign.id
          }
          const r = await CheckGamePlayedApi(v)
          if(r?.data == false) {
            el.src = link.toString()
            el.style.display = "block"
          } else {
            // Already Played
          }
        }
      }
    }
  }

  const checkAndAddPoll = async () => {
    let el = document.getElementById(MainConstants.POLL_DIV_ID)
    if(el) {
      let domain = shopDomain || el.getAttribute("data-id")
      let productId = el.getAttribute("data-product-id")
      let v = {
        shop: domain,
        product_id: productId
      }
      let r = await GetPollDetailsApi(v)
      if(r.success && r.data.id) {
        el.innerHTML = ""
        let t = document.createElement(MainConstants.HTML_ELEMENTS.DIV)
        t.innerHTML = (
          `
          <div>
            <iframe 
                height='305px' 
                src=${getPollUrl(r.data.id)}
                allowfullscreen="true"
                style="width: 100%; max-width: 600px; border: 0px"
            >           
            </iframe>
          </div>
          `
        )
        el.appendChild(t.children[0])
      }
    }
  }

  const giftIcon = () => {
    let widgetSize = (isMobile() ? (widgetRef.current?.widget_mobile_icon_size) : widgetRef.current?.widget_icon_size) ?? widgetRef.current?.widget_icon_size ?? 30
    let widgetIcon = widgetRef?.current?.widget_header_icon
    let widgetCustomIcon = widgetRef?.current?.widget_custom_icon
    if(widgetCustomIcon) {
      return (
        <img
          alt={widgetIcon}
          src={widgetCustomIcon}
          className={"inline-block rounded-full float-left "}
          style={{
            minWidth: widgetSize + "px",
            maxWidth: widgetSize + "px",
            // maxHeight: widgetSize + "px"
          }}
        />
      )
    }
    switch (widgetIcon) {
      case "gift_animation": return(
        <img
          alt={widgetIcon}
          src={LauncherIcons.gift_animation}
          className={"inline-block min-w-[60px] max-w-[60px] rounded-full float-left "}
          style={{
            minWidth: widgetSize + "px",
            maxWidth: widgetSize + "px"
          }}
        />
      )
      case "percent_icon_dark":
      case "percent_icon":
      case "wheel_icon": return (
        <img
          alt={widgetIcon}
          src={LauncherIcons[widgetIcon]}
          className={"inline-block px-[6px] rounded-full float-left"}
          style={{
            minWidth: widgetSize + "px",
            maxWidth: widgetSize + "px"
          }}
        />
      )
      case "gift_icon": return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          width={"24.0pt"}
          height={"24.0pt"}
          stroke="currentColor"
          preserveAspectRatio="xMidYMid meet"
          className={"inline-block p-[6px] float-left "}
          style={{
            minWidth: widgetSize + "px",
            maxWidth: widgetSize + "px",
            minHeight: widgetSize + "px"
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
          />
        </svg>
      )
      case "game_icon": return (
        <svg
          version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="24.000000pt"
          height="24.000000pt"
          viewBox="0 0 54.000000 36.000000"
          preserveAspectRatio="xMidYMid meet"
          className={"inline-block p-[6px] float-left"}
          style={{
            minWidth: widgetSize + "px",
            maxWidth: widgetSize + "px",
            minHeight: widgetSize + "px"
          }}
        >
          <g
            transform="translate(0.000000,36.000000) scale(0.100000,-0.100000)"
            fill={(isMobile() ? widgetRef.current?.mobile_pulltab_color : widgetRef.current?.desktop_pulltab_color) || "white"}
            stroke="none"
          >
            <path d="M89 343 c-42 -26 -52 -51 -70 -178 -15 -105 -15 -123 -3 -142 21 -34
                                45 -28 94 22 l44 45 116 0 116 0 44 -45 c49 -50 73 -56 94 -22 13 19 12 37 -3
                                142 -19 132 -31 158 -77 180 -47 22 -319 21 -355 -2z m73 -60 c2 -22 9 -29 31
                                -31 35 -4 35 -20 0 -24 -22 -2 -29 -9 -31 -30 -2 -16 -7 -28 -12 -28 -5 0 -10
                                12 -12 28 -2 21 -9 28 -30 30 -36 4 -36 20 0 24 21 2 28 9 30 31 2 15 7 27 12
                                27 5 0 10 -12 12 -27z m246 4 c2 -12 -3 -17 -17 -17 -12 0 -21 6 -21 13 0 31
                                32 34 38 4z m-48 -47 c0 -11 -6 -20 -14 -20 -18 0 -29 16 -21 30 11 18 35 11
                                35 -10z m95 0 c0 -8 -8 -16 -17 -18 -13 -2 -18 3 -18 18 0 15 5 20 18 18 9 -2
                                17 -10 17 -18z m-47 -47 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2 12 3
                                17 18 17 15 0 20 -5 18 -17z"
            />
          </g>
        </svg>
      )
      default: return(
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          width={"24.0pt"}
          height={"24.0pt"}
          stroke="currentColor"
          preserveAspectRatio="xMidYMid meet"
          className={"inline-block p-[6px] float-left "}
          style={{
            minWidth: widgetSize + "px",
            maxWidth: widgetSize + "px",
            minHeight: widgetSize + "px"
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
          />
        </svg>
      )
    }
    return null
  }

  const eventClicks = (c_id, main) => {
    try {
      let click_div = main.desktop_click_value
      if(isMobile()) {
        click_div = main.mobile_click_value
        if(!main.mobile_click) click_div = null
      } else {
        if(!main.desktop_click) click_div = null
      }
      // console.log(main, click_div)
      if(click_div && click_div.length > 0) {
        let sep = click_div[0];
        let el = click_div.substring(1)
        if(sep === '.') {
          let e = document.getElementsByClassName(el)
          for(let i of e) {
            i.onclick = () => bannerClick(c_id)
          }
        }
        if(sep === '#') {
          let e = document.getElementById(el)
          if(e) {
            e.onclick = () => bannerClick(c_id)
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    // Make Website Scrolling False if modal open
    document.body.style.overflowY = modalOpen ? 'hidden' : 'auto';
  }, [modalOpen])

  useEffect(() => {
    if(launcherState === MainConstants.LAUNCHER_STATES.LAUNCHED) {
      showNudge()
    }
    if(launcherState === MainConstants.LAUNCHER_STATES.SMALL) {
      showStickyNudge()
    }
  }, [launcherState])

  const showNudge = () => {
    let values = {
      campaign_id: details.campaign.id,
      mobile: yesNo(window.innerWidth < 768),
      utms: window.location.search
    }
    ShowNudgeApi(values)
  }

  const showStickyNudge = () => {
    let campaignId = details.campaign.id
    if(getCookie(CookieNames.CAMPAIGN_OPENED_PREFIX + campaignId)) return
    let values = {
      campaign_id: campaignId,
      mobile: yesNo(window.innerWidth < 768),
      utms: window.location.search
    }
    ShowStickyNudgeApi(values)
  }

  useEffect(() => {
    if(details && !initial) {
      getContainerWidth()
     if(apiCalled) {
       getContainerWidth()
       setInitial(true)
       try {
         addIframes()
       } catch (e) {
         console.error(e)
       }
       // checkUrlAndAddWidget()
       // eventClicks()
     }
    }
  }, [widget, details, initial, apiCalled])

  useEffect(() => {
    if(modalTargetLinks && Object.keys(modalTargetLinks).length && modalTargetLinks != {}) {
      startLauncher()
    }
  }, [modalTargetLinks])

  useEffect(() => {
    if(shopDomain && shopDomain.length>0) {
      getWidgetDetails()
      checkAndAddPoll()
    }
  }, [shopDomain])

  useEffect(() => {
    if(details) {
      checkIfCouponCodeCookie()
      checkThanksPageCampaign()
    }
  }, [details])

  useEffect(() => {
    // console.log("Engagebud Start...")
    init()
  }, [])

  const getContainerWidth = () => {
    let side = 0
    let widgetClassName = "widget_main_body"
    let a = document.getElementById(widgetClassName)
    if((widget?.widget_position || "").includes("right") && a) {
      side = a.offsetWidth
    }
    setSpacing({
      vertical: (widgetRef.current?.nudge_vertical_spacing + (widgetRef?.current?.widget_header_icon.includes("animation") ? 18 : 0)) || 20,
      side: (side + widgetRef.current?.nudge_side_spacing) || 20
    })
  }

  function getWidgetPosition() {
    let midWindowHeight = parseInt(String(window.innerHeight/2));
    let v: React.CSSProperties = {
      bottom: spacing.vertical + "px",
      left: spacing.side + "px"
    }
    switch (widget?.widget_position) {
      case "bottom_right": {
        v = {
          bottom: spacing.vertical + "px",
          right: spacing.side + "px"
        };break;
      }
      case "middle_right": {
        v = {
          top: (midWindowHeight + spacing.vertical) + "px",
          right: spacing.side + "px"
        }
      };break;
      case "middle_left": {
        v = {
          top: (midWindowHeight + spacing.vertical) + "px",
          left: spacing.side + "px"
        }
      };break;
      case "top_left": {
        v = {
          top: (spacing.vertical) + "px",
          left: spacing.side + "px"
        }
      };break;
      case "top_right": {
        v = {
          top: (spacing.vertical) + "px",
          right: spacing.side + "px"
        }
      };break;
    }
    return v
  }

  function getTextColor(link) {
    let cid = null
    if(link) {
      let ns = link.match(/\d+$/)
      cid = ns[ns.length - 1]
    }
    let clr = (link && link.includes("survey")) ? "#000" : (detailsRef.current?.widgets[cid]?.theme?.font_color ?? detailsRef.current?.campaign?.theme?.font_color ?? '#000')
    // console.log(link, cid, (detailsRef.current?.widgets[cid]?.theme?.font_color || detailsRef.current?.campaign?.theme?.font_color || '#000'), clr)
    return clr
  }

  useEffect(() => {
    getContainerWidth()
  }, [launcherState])

  return (
    <div
      id={"engagebud"}
      className={`engagebud-container font-inter fixed ${modalOpen?"":""}`}
      style={{
        zIndex: 99999999
      }}
      onScroll={runOnScroll}
    >
      {
        launcherState === MainConstants.LAUNCHER_STATES.HIDDEN && null
      }
      <div
        id={"engagebud_items"}
      >
        {
          Object.keys(modalTargetLinks).map((a) => {
            let active = modalOpen == a;
            return(
              <div
                id={"openModal_"+a}
                key={String(modalTargetLinks[a])}
                className={`${active?"animate-engagebudPromptFadeSlideUp":"hidden"} z-max-7 shadow-[4px_0_65px_0_#000000] openModal modal-window fixed top-0 left-0 bottom-0 z-max9 visible opacity-100 w-full xs:w-full 2xl:w-1/2 max-w-[767px] min-h-full overflow-auto`}
              >
                {
                  !modalTargetLinks[a] ? null : (
                    <div
                      className={""}
                    >
                      <div
                        className={`fixed left-3 max-w-[30px] top-2 cursor-pointer`}
                        hidden={!modalTargetLinks[a].includes("survey")}
                        onClick={() => {
                          refreshModal(`${GameFrame}_` + a)
                        }}
                      >
                        <img
                          src={backIcon}
                          className={"max-w-[18px]"}
                        />
                      </div>
                      <div
                        className={`fixed right-[16px] top-[16px] md:left-[725px] max-w-[30px] cursor-pointer`}
                        onClick={() => {
                          closeModal(`${GameFrame}_` + a, modalTargetLinks[a] && modalTargetLinks[a].includes("survey"))
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          style={{
                            height: "30px",
                            width: "30px",
                            fill: active ? getTextColor(modalTargetLinks[a]) : "#000",
                            stroke: active ? getTextColor(modalTargetLinks[a]) : "#000"
                          }}
                          className={"inline-block"}
                          fill={active ? getTextColor(modalTargetLinks[a]) : "#000"}
                          key={"close_svg"}
                        >
                          <path
                            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                          />
                        </svg>
                      </div>
                      <iframe
                        key={`${GameFrame}_` + a}
                        id={`${GameFrame}_` + a}
                        className={"opacity-100 z-max min-h-screen w-full"}
                        scrolling={"no"}
                        frameBorder={"0"}
                        src={modalTargetLinks[a]}
                        allow={"clipboard-write"}
                      />
                    </div>
                  )
                }
              </div>
            )
          })
        }
      </div>
      {
        (
          <div
            className={`relative`}
          >
            <div
              className={
                `${(launcherState === MainConstants.LAUNCHER_STATES.SMALL && !modalOpen) ? "" : "hidden"} engagebud-prompt-container z-[2] animate-engagebudPromptFadeSlideUp animate-engagebudPromptFadeSlideDown rounded-[30px] fixed min-h-[50px]`
              }
              id={"widget_main_launcher"}
              style={getWidgetPosition()}
            >
              <div
                className="engagebud-prompt-body cursor-pointer pr-[12px] m-0 absolute bottom-0 left-0 right-0 top-0"
                onClick={(e) => {
                  _openModalStickyNudge(e)
                }}
                id={"EngagebudUIPromptContainer"}
              >
                <div
                  className={"my-0 mx-auto text-center relative"}
                >
                  <div
                    className={"w-fit z-[2] relative"}
                    id={"widget_main_body"}
                  >
                    <div
                      className="inline-flex z-[3] absolute -top-2 -right-2 justify-center items-center text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900"
                      style={{
                        width: "24px",
                        height: "24px"
                      }}
                    >
                      1
                    </div>
                    <div
                      className={"p-[4px] animate-ebDarkPulse outline-none b-0 border-none min-w-max min-w-[60px] min-h-[42px] md:max-w-[320px] rounded-full shadow-xl select-none text-lg flex items-center"}
                      style={{
                        backgroundColor: (isMobile() ? widget?.mobile_pulltab_bg_color : widget?.desktop_pulltab_bg_color) || "#FF4697" || details.bar?.bar_bg_color,
                        color: (isMobile() ? widget?.mobile_pulltab_color : widget?.desktop_pulltab_color) || "white"
                      }}
                    >
                      <div
                        className={"inline-flex items-center"}
                      >
                        {giftIcon()}
                        <span
                          className={`font-proxima whitespace-nowrap font-normal ml-[6px] mr-[12px]`}
                          hidden={!(
                            isMobile() ? (
                              widget?.mobile_pulltab_text
                            ) : (
                              widget?.desktop_pulltab_text
                            )
                          )}
                          style={{
                            fontSize: (isMobile() ? widgetRef.current?.widget_mobile_font_size : widgetRef.current?.widget_font_size) ?? widgetRef.current?.widget_font_size ?? 32
                          }}
                        >
                          {
                            isMobile() ? (
                              widget?.mobile_pulltab_text
                            ) : (
                              widget?.desktop_pulltab_text
                            )
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        launcherState === MainConstants.LAUNCHER_STATES.LAUNCHED && !modalOpen && (
          <div
            className={"relative"}
          >
            <div
              className={
                "engagebud-prompt-container z-max animate-engagebudPromptFadeSlideUp rounded-lg fixed min-h-[160px] left-[10px] right-[10px] h-[64px]"
              }
              id={"widget_main_nudge"}
              style={{
                bottom: "20px"
              }}
            >
              <div
                className={`engagebud-prompt-body pr-[12px] b-0 md:max-w-[320px] rounded-xl shadow-xl absolute bottom-0 left-0 right-0 top-0`}
                id={"EngagebudUIPromptContainer"}
                style={{
                  background: `url(https://i.ibb.co/NNQYxV0/Group-718-1.png), ${widget?.nudge_background || clr}`,
                  color: widget?.nudge_color || "white"
                }}
              >
                <div
                  className={"pt-[32px] pb-[12px] my-0 mx-auto text-center relative"}
                >
                  <div
                    className={"absolute cursor-pointer hover:bg-[hsla(0,0%,100%,.2)] right-[8px] top-[8px]"}
                  >
                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke={widget?.nudge_color || "white"}
                      style={{
                        color: widget?.nudge_color || "white"
                      }}
                      className={"inline-block min-w-[32px] min-h-[32px] float-left "}
                      onClick={() => closeModalLauncher()}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <div
                    className={"flex hidden justify-center"}
                  >
                    <div
                      className={""}
                    >
                      <svg
                        version="1.0" xmlns="http://www.w3.org/2000/svg"
                        width="54.000000pt"
                        height="36.000000pt"
                        viewBox="0 0 54.000000 36.000000"
                        preserveAspectRatio="xMidYMid meet"
                        className={"select-none min-w-[54px] min-h-[36px] inline-block float-left px-2"}
                        style={{
                          color: widget?.nudge_color || "white"
                        }}
                      >
                        <g
                          transform="translate(0.000000,36.000000) scale(0.100000,-0.100000)"
                          fill={widget?.nudge_color || "#fff"}
                          stroke="none"
                        >
                          <path
                            d="M89 343 c-42 -26 -52 -51 -70 -178 -15 -105 -15 -123 -3 -142 21 -34
                               45 -28 94 22 l44 45 116 0 116 0 44 -45 c49 -50 73 -56 94 -22 13 19 12 37 -3
                                142 -19 132 -31 158 -77 180 -47 22 -319 21 -355 -2z m73 -60 c2 -22 9 -29 31
                                -31 35 -4 35 -20 0 -24 -22 -2 -29 -9 -31 -30 -2 -16 -7 -28 -12 -28 -5 0 -10
                                12 -12 28 -2 21 -9 28 -30 30 -36 4 -36 20 0 24 21 2 28 9 30 31 2 15 7 27 12
                                27 5 0 10 -12 12 -27z m246 4 c2 -12 -3 -17 -17 -17 -12 0 -21 6 -21 13 0 31
                                32 34 38 4z m-48 -47 c0 -11 -6 -20 -14 -20 -18 0 -29 16 -21 30 11 18 35 11
                                35 -10z m95 0 c0 -8 -8 -16 -17 -18 -13 -2 -18 3 -18 18 0 15 5 20 18 18 9 -2
                                17 -10 17 -18z m-47 -47 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2 12 3
                                17 18 17 15 0 20 -5 18 -17z"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div
                    className={"font-proxima tracking-normal leading-none font-bold select-none text-[16px] pt-[8px] pb-[24px] font-inherit"}
                    style={{
                      color: widget?.nudge_color || "white"
                    }}
                  >
                    {widget?.nudge_title ?? "Play Games and Win Rewards!"}
                  </div>
                  <div
                    className={"w-fit mx-auto"}
                  >
                    <div
                      className={"font-proxima animate-ebPulse shadow-xl hover:shadow-none cursor-pointer px-[20px] py-[14px] rounded-[12px] font-bold text-[16px]"}
                      style={{
                        backgroundColor: widget?.nudge_button_background || "white",
                        color: widget?.nudge_button_color || "black"
                      }}
                      onClick={() => {
                        openModalLauncher()
                      }}
                    >
                      {widget?.nudge_button_text ?? "Play and Win"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default LauncherComponents