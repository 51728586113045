import React from "react";
// import {captureMessage, captureException} from '@sentry/react'

class ErrorBoundary extends React.Component<{}, {hasError: boolean, error: any}>{
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    }
  }

  static getDerivedStateFromError(error: any) {
    return {hasError: true}
  }

  componentDidCatch(error: { toString: () => any; }, errorInfo: any) {
    // captureMessage("ErrorBoundary", error.toString())
    // captureException(errorInfo, )
    this.setState({error: error.toString()});
    console.log(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary