import "./../assets/scss/App.scss";
import ErrorBoundary from "../Common/Components/ErrorBoundary";
import LauncherComponents from "./LauncherComponents";

export type LauncherStateTypes = "launched" | "small" | "full" | "hidden";

const App = () => {

  return(
    <ErrorBoundary>
      <LauncherComponents />
    </ErrorBoundary>
  )
};

export default App;
